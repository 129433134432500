@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    margin-bottom: 40px;

    @media breakpointMdUp {
      margin-bottom: 120px;
    }
  }

  .listItem {
    list-style-type: none;
  }

  .productGrid {
    display: grid;
    gap: sm;
    grid-template-columns: repeat(3, 1fr);

    @media breakpointMdUp {
      grid-template-columns: repeat(6, 1fr);
      gap: md;
    }
  }

  .loaderWrapper {
    margin: 120px 0;
  }

  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(-2deg);
    }

    75% {
      transform: rotate(2deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  .animatedWrapper {
    animation: wiggle 0.5s linear;
    cursor: grab;
  }
}
